import { getIsTW } from '../../utils/countryInfo';

export const DOWNLOAD_APP_URLS = {
  get ItemPage() {
    if (getIsTW()) {
      return 'https://cdp.data-di.com/r/ca';
    }

    return 'https://cdp.data-di.com/r/da';
  },
  get SearchResultPage() {
    if (getIsTW()) {
      return 'https://cdp.data-di.com/r/ka';
    }

    return 'https://cdp.data-di.com/r/ta';
  },
  get ListPage() {
    if (getIsTW()) {
      return 'https://cdp.data-di.com/r/QA';
    }

    return 'https://cdp.data-di.com/r/ra';
  },
};

export const WHITE_LIST = ['ItemPage', 'SearchResultPage', 'ListPage'];
