import { View } from 'react-native';

import FloatActionButton from '../elements/FloatActionButton';
import styles from './styles';
import { isWebPlatform } from './utils';

export default function () {
  return (
    <>
      <View style={styles.floatIconStack}>
        {isWebPlatform && <FloatActionButton />}
      </View>
    </>
  );
}
