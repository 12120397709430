/* eslint-disable max-len */

import { LoginOrRegisterMethod } from '../../constants/LoginOrRegisterMethod';
import { CommonErrors, MemberErrors, TransactionErrors } from '../../constants/VBErrorCode';
import { MailType, SupportType, TransactionStatus } from '../../types/Common';
import { DeliveryMethods, PaymentMethods } from '../../types/VBConfigResponse';
import { VendorType } from '../../types/VendorType';

const NUMBER_FORMAT = ['一', '二', '三', '四', '五', '六', '七', '八', '九'];

export default {
  'VeryBuy-title': 'VeryBuy Fashion | 多風格時尚購物平台',
  'vb-title-suffix-long': 'VeryBuy Fashion | 多風格時尚購物',
  'vb-title-suffix-medium': 'VeryBuy 多風格時尚購物',
  'vb-title-suffix-short': 'VeryBuy 時尚選品',
  'vb-default-keyword': '女裝、旗袍、洋裝、外套、禮服',
  'landing-page-desc': 'VeryBuy 提供最新的「%{name}」，眾多時尚流行款式，快來 VeryBuy 選購吧！',
  'VeryBuy-og-description': '女性專屬時尚購物平台，天天上新品、每日驚喜優惠不間斷，你的日常、通勤、約會服飾 VeryBuy 全都包，快來 VeryBuy 輕鬆搭配你的穿搭吧！',
  'alert-default-positive-text': '好的',
  'alert-default-negative-text': '取消',
  'crash-alert-message': '很抱歉系統異常出了點問題！\n請您先嘗試關閉程式後重新開啟，\n我們將趕緊回報工程師處理',
  'crash-alert-negative': '回報問題',
  'crash-alert-positive': '重新載入',
  'webview-error-content': '抱歉！此頁面暫時卡住了',
  'webview-error-content-with-cvs': '請再嘗試一次或選擇其他運送方式',
  'item-page-desc-meta': '推薦給你「%{title}」，搭配每日驚喜優惠，讓你買到手軟，更多「%{categoryName}」等你來挖掘！',
  'search-result-page-desc-meta': '在 VeryBuy 搜尋%{queryText}，你想找的%{queryText}應有盡有，下載 App 再送專屬優惠，各大網紅推薦的人氣商品盡在 VeryBuy！',
  'categories-list-page-desc-meta': '最齊全的%{cat}系列！你想找的%{subCats}應有盡有，眾多%{cat}商品盡在 VeryBuy！',
  'loading': '載入中...',
  'later': '稍後再說',
  'total': '總計',
  'products-total': '商品總計',
  'country': {
    'Taiwan': '台灣',
    'Singapore': '新加坡',
    'Global': '全球',
  },
  'categories-new-arrivals': '新品上架',
  'member-contact-auth': {
    'title': '驗證碼已發送',
    'subtitle': '請輸入驗證碼',
    'note': '因系統傳送問題，驗證碼發送有可能等待數秒鐘到一分鐘若超過五分鐘仍收不到驗證碼，請聯絡客服',
    'resend': '重新發送',
    'resend-retry': '%{sec} 秒後重試',
  },
  'member-contact-auth-error': {
    '2002': '輸入的認證碼已失效，請重新發送',
    '1017': '認證碼錯誤，請重新輸入',
  },
  'freight-included': '含運費',
  'member-edit-tab': {
    'info': '個人資料',
    'password': '密碼變更',
  },
  'love-page-tab': {
    'item': '商品',
    'vendor': '賣家',
  },
  'faq-page-tab': {
    'top-faq': '熱門問題',
    'more-topics': '以分類查詢',
  },
  'vendor-type': {
    [VendorType.Seller]: '賣家',
    [VendorType.GoodSeller]: '優良賣家',
    [VendorType.Flagship]: '品牌旗艦店',
  },
  'vendor-offline': '已下架',
  'remove-isLoved': '移除',
  'explore-more-vendors': '探索更多品牌',
  'into-the-vendor': '進店逛',
  'cat-scroll-hint': '輕輕一滑看更多分類！讓你更方便地探索所有商品！',
  'freight-discount-included': '含運費、已折抵',
  'freight-not-included': '不含運費',
  'api-common-error-msg': '糟糕！目前好像遇到亂流 %{errorCode}',
  'api-common-error-msg-try-again': '糟糕！目前遇到網路亂流\n請稍候再試一次',
  'verify-sms-error-message': {
    '1001': '無法完成驗證，請重新確認您的手機號碼是否輸入正確',
    '1019': '驗證碼已發送，請確認是否收到簡訊，若有問題請洽詢客服',
  },
  'login-fail': {
    [CommonErrors.ERR_NOT_FOUND]: '帳號或密碼錯誤！',
    [MemberErrors.ERR_MEMBER_NEED_LOGIN_BY_APPLE]: '這個帳號需要使用第三方（Facebook, Apple 等）登入唷！',
    [MemberErrors.ERR_MEMBER_NEED_LOGIN_BY_FACEBOOK]: '這個帳號需要使用第三方（Facebook, Apple 等）登入唷！',
    [MemberErrors.ERR_MEMBER_NEED_LOGIN_BY_FACEBOOK_OR_APPLE]: '這個帳號需要使用第三方（Facebook, Apple 等）登入唷！',
    [MemberErrors.ERR_MEMBER_NEED_LOGIN_BY_LINE]: '這個帳號需要使用第三方（Facebook, Apple 等）登入唷！',
  },

  'register-fail-default': '糟糕！註冊失敗了',
  'register-fail': {
    [MemberErrors.ERR_MEMBER_EMAIL_USED]: '此 E-mail 帳號已經被註冊過囉！',
    [MemberErrors.ERR_MEMBER_PASSWORD_INSUFFICIENT_STRENGTH]: '密碼強度不足，請輸入 8～16 個英數字組合的密碼',
    [MemberErrors.ERR_MEMBER_PASSWORD_INSUFFICIENT_LENGTH]: '密碼長度不符規定，請輸入 8～16 個英數字組合的密碼',
    [MemberErrors.ERR_MEMBER_INCORRECT_EMAIL]: '您的 E-mail 格式輸入錯誤，請再試一次',
    [MemberErrors.ERR_MEMBER_BLACK_EMAIL]: '請使用真實的 E-mail 註冊，才不會漏接 VeryBuy 非常勸敗最新的優惠訊息喔！',
  },
  'not-filled': '尚未填寫',
  'not-selected': '尚未選擇',
  'not-filled-gift': '尚未填寫，填寫領好禮',
  'basicInfo': '基本資料',
  'basicInfo-name': '姓名',
  'basicInfo-nickname': '顯示暱稱',
  'basicInfo-gender': '性別',
  'basicInfo-birthday': '生日',
  'basicInfo-placeholder': {
    'name': '請輸入您的真實姓名',
    'nickname': '請輸入您欲顯示的暱稱',
    'gender': '請選擇性別',
    'birthday': '請選擇出生年月日',
    'footer-message': '* 填寫生日後將無法修改，系統會在每年生日前十五天，自動發放生日禮 100 元勸敗點歸戶',
    'footer-message-without-vcoin': '* 填寫生日後將無法修改',
  },
  'verify-failed': '驗證失敗',
  'verify-failed-reach-limit-content': '您已嘗試多次驗證，請稍後再試',
  'verify-failed-exists-content': '填寫的信箱或手機號碼已認證過\n請返回再試一次',
  'vendor-event-banner': '獨家優惠',
  'vendor-product-amount': '共 %{amount} 件商品',
  'vendor-score-no-data': '尚無資料',
  'vendor-score-reviews': '用戶評價',
  'vendor-score-quality': '商品品質',
  'vendor-score-shipping-perf': '出貨表現',
  'vendor-score-info': '5 分',
  'search-title': '%{query}－好物推薦',
  'magnitude-description': '星等說明',
  'vendor-score-reviews-description': '代表該賣家 <highlight>所有商品獲得的評價</highlight> 平均分數',
  'vendor-score-quality-description': 'VeryBuy Fashion 平台基於「商品品質」「用戶回饋」給予評分，星星越多代表 <highlight>商品品質良好，高於平均值</highlight>',
  'vendor-score-shipping-perf-description': 'VeryBuy Fashion 平台基於「賣家收到訂單後平均出貨時間」核定，星星越多代表 <highlight>出貨速度良好，高於平均值</highlight>',
  'back-and-retry': '返回重試',
  'contactInfo': '聯絡資料',
  'contactInfo-phone': '手機號碼',
  'contactInfo-email': '信箱',
  'contactInfo-subscription': '訂閱服務',
  'contactInfo-edm-subscribed': '已訂閱電子報',
  'contactInfo-placeholder': {
    'phone': '請輸入手機號碼',
    'email': 'example@gmail.com',
    'footer-message': '※ 完成驗證手機號碼與信箱，各獲得 50 元抵用券歸戶',
    'footer-message-verified': '※ 您已完成認證，若需要修改聯絡資料，請聯絡客服',
    'subscription': 'VeryBuy Fashion 最新電子報',
  },
  'member-edit-invalid-phone': '號碼格式錯誤！請輸入正確的手機號碼',
  'gender': {
    '1': '男',
    '2': '女',
    '3': '其他',
  },
  'select-date': '選擇日期',
  'unverified': '未驗證',
  'verified': '已驗證',
  'verify': '驗證',
  'none': '無',
  'member-profile': '會員資料維護',
  'member-info-edit': '編輯基本資料',
  'member-contact-edit': '編輯聯絡資料',
  'successfully-saved': '資料儲存成功',
  'watch-size': '看尺寸',
  'follow': '加入追蹤',
  'following': '追蹤中',
  'follow-fail': '嗚！按鈕卡住了，請再試一次',
  'people-likes': '%{count} 人喜歡',
  'view-amount': '%{amount} 個人都在看',
  'view-all': '查看全部',
  'code': 'zh-TW',
  'name': '繁體中文',
  'about': '版本資訊',
  'version': '目前版本',
  'device-id': '裝置 ID',
  'member-phone-verify-error-message': {
    '1017': '驗證碼錯誤，請再輸入ㄧ次',
    '1019': '你已嘗試多次驗證，請稍候再試',
    '2002': '無法完成驗證，請重新輸入您的電話號碼',
  },
  'fitting-report': '真人試穿報告',
  'product-detail-title': '商品介紹',
  'product-detail-videos-title': '商品影片',
  'copied-to-clipboard': '已複製到剪貼簿',
  'copied-account-number': '已複製轉帳帳號',
  'copied-transaction-no': '已複製訂單編號',
  'cat-tab-hint': '找商品快速又便利！',
  'me': '我',
  'cart': '購物車',
  'goto-cart': '查看購物車',
  'category': '分類',
  'keywords': '關鍵字',
  'verybuy': '非常勸敗',
  'new-arrivals-default-tab': '每週新貨到',
  'leaveMsgInDialog': '確定離開並放棄變更',
  'quit': '放棄',
  'edit': '編輯',
  'continueEdit': '繼續編輯',
  'save': '儲存',
  'burger-hint-title': '點擊查看全站分類！',
  'burger-hint-subtitle': '輕鬆找到您想要的優質好物，快來逛逛！',
  'breakEdit': '不儲存',
  'reset': '重設',
  'filter': '篩選',
  'apply': '套用',
  'filter-class': '選擇類別',
  'search-labels-hint': '透過標籤找商品快速又便利！',
  'price-range': '價格範圍 (%{symbol})',
  'color-filter': '顏色',
  'pattern-filter': '圖案',
  'below-price': '%{price} 以下',
  'above-price': '%{price} 以上',
  'lowest-price': '最低價格',
  'category-by-style': {
    'title': '按%{style}選購',
    'child-categories': '款式',
    'recommend-labels': '風格',
  },
  'sms-verify': '我們已發送驗證碼到您的手機，若沒接收到簡訊請洽詢客服。',
  'highest-price': '最高價格',
  'refresh': '重新整理',
  'new-message': '你有新訊息',
  'welcome-to-login-or-register': '歡迎註冊／登入',
  'login-register': '登入 / 註冊',
  'logout': '登出',
  'waiting-to-resend-verification-code': '%{number} 秒後可重新傳送驗證碼',
  'refetch-verify-code': '重新發送',
  'not-receive-verification-code': '沒收到驗證碼嗎？',
  'login': '登入',
  'log-in-now': '立即登入',
  'add-verify-phone-number-error': {
    '1018': '此手機號碼已被使用，若有問題請洽詢客服',
    '1016': '無法完成驗證，請重新確認您的手機號碼是否輸入正確',
  },
  'login-facebook': '使用 Facebook 登入',
  'login-apple': '使用 Apple 登入',
  'register': '註冊',
  'noAccountYet': '還沒有帳號嗎？',
  'createOne': '建一個吧',
  'subscribeNewsletter': '訂閱電子報 ( 隨時掌握 VeryBuy 給您的專屬優惠呦 ! )',
  'registrationStandsForAgreement': '註冊表示你已經閱讀並同意',
  'memberTreaty': '會員條款',
  'and': '與',
  'event-task': '賺點任務',
  'event-task-rules': '查看規則',
  'event-task-button-text': {
    '0': '去驗證',
    '1': '去驗證',
    '2': '立即領取',
    '3': '已領取',
    '4': '去驗證',
    '5': '已領取',
    '6': '去驗證',
    '7': '已過期',
  },
  'view-my-vcoin': '查看我的勸敗點',
  'view-my-coupon': '查看我的抵用券',
  'event-task-hint': '驗證手機與 Email 才能解鎖任務唷！',
  'event-deadline': '任務期限：%{date}',
  'task-rule': '任務規則',
  'privacyPolicy': '隱私權政策',
  'hasAccount': '已經有帳號嗎？',
  'directlyLogin': '直接登入',
  'on-sale-ad-title': '新人專享優惠',
  'on-sale-ad-subtitle': '5 折起',
  'on-sale-ad-btn': '去搶購',
  'logoutConfirm': '您確定登出嗎？',
  'nav-left-confirm': '確定要離開嗎？',
  'confirm': '確定',
  'cancel': '取消',
  'or': '或',
  'guest-back-desc': '網路連線不穩定，請點擊按鈕重新連線',
  'askLogin-desc': '親愛的顧客，您的登錄已失效\n請重新登入',
  'askLogin-positive': '重新登入',
  'lightboxLoading-error-positive': '好的',
  'transaction-error-default-desc': '抱歉！您的訂單送出失敗\n請再嘗試一次',
  'transaction-error-already-paid': '已付款完成',
  'welcomeLogin-header': '新用戶專屬',
  'welcomeLogin-promote': ' 首次下單，付款後送 $%{vcoin} 活動勸敗點',
  'welcomeLogin-login-desc': '或以 E-mail 登入或註冊',
  'item-sku-modal-hint': 'APP 下單滿額送 10%',
  'item-sku-modal-hint-gc': '使用 APP 購物體驗更順暢！',
  'item-sku-modal-button': 'APP下單',
  'checkOrder': '查詢訂單',
  'successOrder': '訂購成功',
  'searchPlaceHolder': '搜尋非常好物',
  'creditCardPaymentPage': '信用卡付款',
  'home-search-placeholder': '打開 APP 搜好物，享 $100 活動勸敗點！',
  'new-friends-gifts': '新朋友好禮專區',
  'register-campaign': '登錄活動',
  'item-page-label-section-title': '其他人也搜尋了',
  'cart-missed': '不小心錯過了',
  'too-slow': '嗚～你慢了一步',
  'sold': '銷售一空',
  'hi': 'Hi',
  'hello': '你好',
  'account': '帳號',
  'email': '電子信箱',
  'password': '密碼',
  'password-placeholder': '需為 8 ~ 16 個且英數字混合喔',
  'delivery-name-placeholder': '請填寫您的真實姓名',
  'forgot-password': '忘記密碼了？',
  'set-new-pwd-step1-form-title': '找回密碼',
  'set-new-pwd-step1-form-hint-1': '請輸入您註冊 VeryBuy 時的電子信箱，我們會發送含有重設密碼之連結的信件至您的信箱。',
  'set-new-pwd-step1-form-hint-2': '<hint> 若您忘記帳號，請 </hint><touch>重新註冊</touch><hint> 或 聯絡客服</hint>',
  'set-new-pwd-mail-request-countdown': '沒收到信件？再次發送 (%{countdown}s)',
  'set-new-pwd-mail-request-success': '發送成功',
  'set-new-pwd-mail-request-failed-with-404': '此帳號使用第三方（Facebook、Apple 等） 登入，無法重新設定密碼，請返回並使用第三方登入',
  'email-not-found': '找不到這個 Email 的註冊紀錄',
  'send-reset-password-mail': '發送重設密碼信',
  'reset-password-form-title': '變更密碼',
  'reset-password-form-hint': '請填入您欲使用的新密碼，密碼須為 8 ~ 16 個且英數字混合。',
  'reset-password-success': '密碼變更成功，請重新登入',
  'reset-password-failed-dialog': {
    'title': '變更密碼失敗',
    'content': '您已超過重設的時間限制，為了您的帳號安全，請再次提出變更密碼申請',
    'positive-text': '再次申請',
  },
  'old-password': '舊密碼',
  'old-password-incorrect': '舊密碼輸入錯誤\n請再次確認',
  'old-password-placeholder': '請輸入您目前使用的密碼',
  'retry-input': '重新輸入',
  'new-password': '新密碼',
  'new-password-placeholder': '請輸入新密碼',
  'new-password-placeholder-case2': '密碼需為 8 ~ 16 個且英數字混合',
  'confirm-new-password': '確認新密碼',
  'confirm-new-password-placeholder': '請再次輸入新密碼',
  'confirm-reset-password': '確認變更',
  'confirm-password-different': '兩次密碼輸入不相符，請再次確認',
  'new-password-required': '新密碼不可為空',
  'old-password-required': '舊密碼不可為空',
  'new-password-same-err': '新密碼不能與舊密碼相同，請重新輸入',
  'new-password-confirm-err': '兩次輸入密碼不一致，請重新輸入',
  'reset-password-invalid-title': '連結已失效',
  'reset-password-invalid-hint': '密碼重設連結已失效，請重新申請變更密碼',
  'reset-password-apply-again': '再次申請',
  'form-validate-required': '必填',
  'invalid-form-required': '此欄位為必填喔',
  'invalid-login-email': 'E-mail 格式錯誤，例：example@gmail.com',
  'invalid-login-password': '密碼需為 8 ~ 16 個且英數字混合喔',
  'invalid-phone': '請輸入正確的手機號碼',
  'invalid-zip-code': '請輸入正確的郵遞區號',
  'login-fail-default-desc': '嗚！登入卡住了，請再試一次',
  'update-fail-default-desc': '更新失敗，請重試',
  'invalid-recipient-argument-desc': '更新寄貨資料失敗，資料有誤',
  'noMoreItems': '到底了，看其他分類找好物',
  'noMoreTransactions': '沒有更多訂單了！',
  'choose-payment-label': '請選擇付款方式',
  'payment-group-card': '信用卡付款',
  'payment-new-card': '使用新卡號',
  'payment-choose-card': '管理卡片',
  'keepShopping': '繼續逛逛',
  'no-support': '此商品不提供',
  'cod-both': '宅配 / 超商貨到付款',
  'cod-home': '宅配貨到付款',
  'hasCOD': '貨到付款',
  'hasCVS': '超商取貨',
  'noCVS': '無法超商取貨',
  'subtotal': '商品小計',
  'paymentFee': '手續費',
  'shippingFee': '運費',
  'internalShippingFee': '國內運費',
  'crossBorderShippingFee': '跨境物流費',
  'shippingTracking': '物流追蹤',
  'goToQuery': '前往查詢',
  'vcoin': '勸敗點',
  'point': '點',
  'vcoin-bill-detail': '勸敗點折抵',
  'event-vcoin-bill-detail': '活動勸敗點折抵',
  'storage-vcoin-bill-detail': '購物金折抵',
  'coupon': '抵用券',
  'coupon-discount': '優惠折抵',
  'coupon-discount-label': '折抵 %{value} 元',
  'coupon-bill-detail': '抵用券折抵',
  'use-coupon-code': '使用折扣碼',
  'totalPrice': '總計',
  'total-payment-amount': '總付款金額',
  'redeem': '兌換',
  'cancel-redeem': '取消折抵',
  'coupon-cancel-bar-label': '已選擇 1 張抵用券，折抵',
  'dollar': '元',
  'index-on-sale-title-center': '精選下殺',
  'index-on-sale-title-left': '天天上新品',
  'index-on-sale-title-right': '搶購去',
  'coupon-cancel-bar-cancel': '取消選取',
  'coupon-select': '選擇抵用券',
  'coupon-placeholder': '點擊輸入折扣碼',
  'coupon-default-fail': '系統發生錯誤，請再嘗試一次唷！',
  'coupon-default-success': '抵用成功！折抵 %{value} 元',
  'coupon-detail': '詳細說明',
  'coupon-label-expiring': '即將到期',
  'coupon-label-under-threshold': '未達門檻',
  'coupon-label-new': 'New',
  'coupon-tab-available': '可使用',
  'coupon-tab-used': '已使用',
  'cart-empty': '目前這裡有點冷清',
  'confirm-remove-cart-item': '是否刪除此商品？',
  'coupon-tab-expired': '已過期',
  'coupon-error-not-found': '此優惠不存在唷，請確認折扣碼是否輸入正確',
  'coupon-error-expired': '抱歉！優惠已過期無法使用',
  'coupon-error-used': '你已經使用過囉！（每組折扣碼只能兌換一次）',
  'coupon-error-redeemed': '此折扣碼兌換過囉，要記得去使用唷！',
  'coupon-error-else': '咦？此折扣碼無法使用，試試看其他的優惠吧',
  'no-coupon': '你還沒有抵用券唷',
  'notification-empty-login': '你還沒有登入，趕緊登入查看消息吧！',
  'notification-empty': '你還沒有收到任何消息',
  'recently-no-coupon': '你最近沒有抵用券唷',
  'used-in': '使用於',
  'use-period': '使用期限至',
  'no-use-period': '無使用期限',
  'SearchNotFoundTitle': '糟糕，找不到你要的商品',
  'SearchNotFoundSubTitle': ' 試試其他關鍵字',
  'SearchNotFoundHint': '換個關鍵字試試！',
  'filter-not-found-subtitle': '試試其他篩選條件',
  'unpaid': '待付款',
  'couponRedeemed': '折扣碼兌換成功\n記得按下「完成」才能抵用唷！',
  'rating-text': {
    '0': '尚未評分',
    '1': '非常不滿意',
    '2': '不滿意',
    '3': '普通',
    '4': '滿意',
    '5': '非常滿意',
  },
  'success-rating': '評價送出成功',
  'paid': '已付款',
  'orderDetail-status': {
    [TransactionStatus.Unpaid]: '待付款',
    [TransactionStatus.Paid]: '已付款',
    [TransactionStatus.Stocking]: '備貨中',
    [TransactionStatus.Sent]: '已出貨',
    [TransactionStatus.Expired]: '訂單已取消',
  },
  'orderDetail-delivery-date': '最晚出貨日',
  'orderDetail-delivery-info': '最晚出貨日',
  'orderDetail-transaction-no': '訂單編號',
  'orderDetail-created-at': '建立日期',
  'orderDetail-logistics-provider': '配送快遞',
  'orderDetail-local-ship-no': '包裹查詢編號',
  'orderDetail-more': '查看詳情',
  'orderDetail-credit-success': '付款成功',
  'orderDetail-credit-failure': '付款失敗！%{msg}',
  'orderDetail-payment-text': '付款方式',
  'orderDetail-payment-limit-hint': '請務必在期限內完成付款唷',
  'orderDetail-this-order-only': '僅供本次交易使用',
  'orderDetail-total-price': '付款金額',
  'orderDetail-payment-limit': '付款期限',
  'orderDetail-paid-at': '付款時間',
  'orderDetail-total-quantity': '共 %{quantity} 件商品',
  'orderDetail-bank-name': '轉帳銀行',
  'orderDetail-account-name': '戶名',
  'orderDetail-account-number': '轉帳帳號',
  'orderDetail-cvs-code': '繳費代碼',
  'orderDetail-retry-atm': '使用 ATM 付款',
  'orderDetail-retry-card': '重新刷卡',
  'orderDetail-retry-line-pay': '重新使用 LINE Pay',
  'orderDetail-retry-apple-pay': '重新使用 Apple Pay',
  'receiptID': '發票號碼',
  'receiptCreateAt': '開立日期',
  'orderDetail-use-card': '使用信用卡付款',
  'orderDetail-account-refreshing': '匯款帳號產生中...（%{sec}）',
  'orderDetail-account-failed': '帳號產生失敗，請試試下拉刷新',
  'transaction-date': '訂單日期',
  'oridnal-item': '第 %{oridnal} 件商品',
  'rate': '評價',
  'view-rate': '查看評價',
  'rate-transaction': '評價此訂單',
  'rate-transaction-submit-button': '送出評價',
  'has-done-rate': '已完成評價',
  'rate-leave-rating-dialog-msg': '現在離開將無法保留您的評價\n確定要離開嗎？',
  'rate-continue-rating': '繼續評價',
  'rate-break-rating': '離開',
  'review-deadline-text': '在 %{reviewDeadline} 前評價商品',
  'review-vcoin-text': '即可獲得 %{vcoin} 元勸敗點',
  'item-reviews-title': '商品評價',
  'item-combo-title': '試試這樣搭配',
  'sku-size': '尺寸：',
  'sku-color': '顏色分類：',
  'recommend-combo': '推薦搭配',
  'total-combo-items': '已選 %{totalQuantity} 項搭配商品',
  'review-section-cta': '查看所有評價',
  'review-section-empty': '目前尚無評價',
  'item-total-reviews': '本商品有 %{total} 則評價',
  'end-of-reviews': '到底了，暫無其他評價',
  'feedback': '使用者回饋',
  'eventPage': '活動專區',
  'vcoinPage': '我的勸敗點',
  'mailboxPage': '我的信件匣',
  'refundPage': '退貨申請',
  'supportPage': '聯絡客服',
  'shareFriendPage': '邀請好友賺獎金',
  'transactionDetailPage': '訂單詳情頁',
  'notifications-page': '通知中心',
  'notifications-page-turn-on-noti-guide': {
    'dialog-text': '<text1>你有未讀訊息</text1><text2>立即開啟通知，不錯過任何消息</text2>',
    'noti-bar-for-unread': '<b>立即開啟通知</b>，不錯過任何重要訊息！',
    'noti-bar--title-for-read': '您已閱讀完所有通知',
    'noti-bar--content-for-read': '建議開啟通知，即時掌握最新消息',
  },
  'onsale-page': '精選下殺',
  'serviceTime': '週一至週五 10:00-19:00 (例假日休息)',
  'contactToService': '聯繫客服',
  'faqPage': '常見問題',
  'itemDetail-out-of-stock': '抱歉！我賣光了',
  'itemDetail-is-not-allowed-hint': '此商品不可購買',
  'itemDetail-is-not-allowed-text': '基於法令限制／體積限制／安全性考量等原因，此商品在您所在區域無法購買',
  'itemRecommend': '為您精選',
  'itemEnd': '到底了，想看更多非常好物？',
  'backToHomePage': '逛逛首頁',
  'go-to-home-page': '回到首頁',
  'back-to-home-page': '回首頁',
  'special-for-you': '為你推薦',
  'pick-for-you': '為你精選',
  'itemDetail': '商品詳情',
  'recommend': '推薦更多',
  'show-more': '顯示更多',
  'show-products': '顯示 %{count} 筆商品',
  'recommend-cat': '推薦分類',
  'search-discovery': '每日新發現',
  'default-event': '活動',
  'free-delivery': '免運',
  'price-matching': '優惠',
  'news': '新鮮報',
  'empty-transaction-list': '你還沒有任何訂單唷',
  'empty-browse-history-list': '你還沒有任何瀏覽紀錄唷',
  'go-receive': '馬上去領',
  'go-to-order': '手刀下單',
  'go-shopping': '馬上逛逛',
  'goShopping': '去逛逛',
  'go-to-other-places': '先逛其他地方',
  'empty-product-list': '噢！目前沒有商品',
  'delivery-info': '個工作天出貨',
  'no-more-onboarding-item': '到底了，想看其他人都在買什麼？',
  'receiver': '收件人',
  'recipientAddress': '收貨地址',
  'convenienceStoreName': '取貨門市',
  'chooseCreditCard': '管理信用卡',
  'shippingLogistics': '物流詳情',
  'redeem-right-now': '立即領取',
  'already-redeemed': '已領取',
  'coupon-not-available': '發送完畢',
  'coupon-guide-step1-title': '還沒用過抵用券嗎？',
  'coupon-guide-step1-content': '你可以在「%{eventPage}」領取抵用券（小提醒：放太久可是會過期的，要記得去使用唷！）',
  'coupon-guide-step1-button': '要怎麼使用',
  'coupon-guide-step2-title': '結帳用券享優惠',
  'coupon-guide-step2-content': '結帳時，從「%{couponDiscount}」選擇你想要使用的抵用券，按下「%{finish}」後就可以成功折抵囉！',
  'coupon-guide-step2-button1': '現在就去領 GO!',
  'coupon-guide-step2-button2': '好的，我知道了',
  'verify-quit-confirm': '您尚未成立訂單，確定放棄驗證？',
  'verify-quit': '不驗證',
  'verify-quit-continue': '繼續驗證',
  'submit': '送出',
  'confirmToCancelCreditCardPayment': '確定要取消信用卡付款嗎？',
  'continuePaying': '繼續付款',
  'putPrimaryCardErrorDefaultDesc': '抱歉！發生錯誤，請再試一次',
  'putPrimaryCardErrorInvalidArgument': '信用卡格式錯誤，請再次確認',
  'maximum-item': '已超過可購買數量',
  'added-success': '加入成功',
  'check-cart': '趕緊去購物車看看唷',
  'have-yet-to-choose': '尚末選擇',
  'buy-it': '立即購買',
  'add-to-cart': '加入購物車',
  'out-of-stock': '缺貨中',
  'amount': '數量',
  'retry': '再試一次',
  'my-page-banner-text-guest': 'APP 下單滿額送 10%',
  'my-page-banner-text-guest-gc': '感受 APP 更順暢的購物體驗！',
  'my-page-banner-text-member': '感受 APP 更順暢的購物體驗！',
  'my-page-banner-button': '打開 APP',
  'open-the-app': '打開',
  'item-page-app-download-default-title': '用 APP 下單另享優惠',
  'item-page-app-download-default-title-gc': '打開 APP 購物體驗更順暢',
  'item-page-app-download-default-text': 'APP 下單滿額送10% (買越多送越多)',
  'item-page-app-download-default-text-gc': '立即下載體驗看看吧！',
  'sku': '規格',
  'review-item-sku': '規格：%{sku}',
  'select-style': '選擇款式',
  'select-discount': '選擇優惠方案',
  'select-discount-hint': '擇一使用，優惠不得併用',
  'list-page-title': '經典%{cat}系列推薦',
  'class': '類別',
  'class-title': '全部分類',
  'class-desc': '超過 20 種服飾類別，包括上衣、褲子、外套、洋裝、禮服、包包、配件、童裝、泳裝、鞋子等，一次買齊整套穿搭！',
  'new-arrivals-share-message': '這裡每週都有新品耶，分享給你快來一起逛逛吧！',
  'on-sale-share-message': '這裡天天都有超殺折扣商品，分享給你一起省荷包哦！',
  'item-share-message': '你看這個商品很不錯耶，分享給你',
  'campaign-share-message': '快看 VeryBuy 本月新活動，眾多優惠享不完：',
  'default-share-message': '這裡商品都好好看耶，分享給妳快來一起逛逛吧！',
  'press-again-to-exit-app': '再按一次退出應用程式',
  'notification-channel-default': '一般通知',
  'cvs-pick-up-goods-hint': '取貨時，請記得攜帶身分證件，讓門市人員核對身份順利取貨唷！',
  'provide-name-for-shop-hint': '請提供與身分證相符的真實姓名，讓門市人員查驗，方可順利取件唷！',
  'choose-receiving-shop': '選擇取貨門市',
  'change-receiving-shop': '更改取貨門市',
  'delivery-method-home': '宅配',
  'delivery-method-home-cod': '宅配貨到付款',
  'delivery-method-csv': '7-11',
  'delivery-method-csv-cod': '7-11 貨到付款',
  'home-delivery': '宅配（提供貨到付款）',
  'home-delivery-for-gc': '宅配',
  'convenience-store-pickup': '7-11 取貨',
  'shipping-error-title': '無法超商取貨：',
  'shipping-cod-error-title': '無法宅配貨到付款：',
  'go-paying': '去付款',
  'edit-home-delivery-info': '編輯收件人資訊',
  'create-home-delivery-info': '新增收件人資訊',
  'edit-cvs-delivery-info': '編輯收件門市資訊',
  'create-cvs-delivery-info': '新增收件門市資訊',
  'select-shipping-method': '請選擇運送方式',
  'shipping-method-with-symbol': '運送方式：',
  'receipt-type-with-symbol': '發票類型：',
  'receipt-type': '發票類型',
  'receipt-note': '請留意：一旦選定發票類型後，之後無法再變更',
  'receipt-desc': '辦理退貨與退款時，由 VeryBuy 代為處理發票及銷貨退回證明單，以加速退款作業。',
  'duplicate-uniform-receipt': '個人戶 (二聯) 電子發票',
  'triplicate-uniform-receipt': '公司戶 (三聯) 電子發票',
  'copy': '複製',
  'copied-your-ship-no': '已複製您的包裹編號',
  'my-order': '我的訂單',
  'my-coupon': '我的抵用券',
  'my-collection': '我的收藏',
  'collection': '收藏',
  'my-browse-history': '我的瀏覽紀錄',
  'already-collection': '已收藏',
  'not-yet-collection': '加收藏',
  'browse-history': '瀏覽紀錄',
  'collection-successful': '收藏成功',
  'collection-failed': '收藏失敗',
  'collection-removed': '已移除',
  'collection-removed-failed': '移除失敗',
  'collection-canceled': '取消收藏',
  'collection-cancellation-failed': '取消收藏失敗',
  'collection-full': '您的收藏數量已滿\n建議先清理一下喔',
  'collection-offline': '嗚！已售完商品無法收藏',
  'cart-full': '您購物車裡的商品數已滿\n建議先結帳或清理一下喔',
  'sold-out': '我賣光了',
  'is-not-allowed': '不可購買',
  'deprecated-first-love-dialog-content': '你可以在「我」裡查看你珍藏的商品喔',
  'first-love-dialog-content': '你可以在「我」裡查看你珍藏的商品或賣家喔',
  'not-yet-collect-hang-around': '你還沒有收藏，出發去逛逛',
  'not-yet-collect-vendor': '你還沒有收藏的賣家哦！',
  'cancel-order': '取消訂單',
  'no-receipt-required': '訂單金額 0 元無需開立發票',
  'no-payment-required': '已全額折抵，無需另外繳費',
  'payment': '付款',
  'delivery': '配送',
  'cancel-order-dialog-title': '確定取消訂單？',
  'cancel-order-dialog-title-failed': '取消訂單失敗',
  'cancel-order-dialog-content': '提醒您～訂單取消後，已使用的優惠抵用券無法保留，折抵的勸敗點將會退還給您，請您再次確認是否取消訂單。',
  'confirm-submit-order': '確認付款',
  'confirm-cancel': '確定取消',
  'confirm-select': '確認使用',
  'give-up-cancel': '我再想想',
  'got-it': '我知道了',
  'block-message': '很抱歉，您已違反網站使用規範\n暫無法提供您購物服務。',
  'look-up-logistics': '查詢物流詳情',
  'product-details': '商品明細',
  'separate-shipment': '分批出貨',
  'unshipped': '未出貨',
  'number': NUMBER_FORMAT,
  'separated-shipment-hint': '此筆訂單採分批出貨',
  'search-form-desc': '輸入商品關鍵字\n搜尋你要的非常好物喔',
  'recent-search': '最近搜尋',
  'hot-search': '熱門搜尋字',
  'hot-categories': '常用分類',
  'recommend-subjects': '穿搭主題推薦',
  'clear-histories': '清空',
  'clear-stockout': '清空',
  'clear-stockout-dialog-content': '確定清空售完的商品嗎？',
  'clear-stockout-dialog-positive': '確定清空',
  'clear-stockout-dialog-negative': '我再想想',
  'request-position-permission-title': '當你選擇超取門市時，允許「超取地圖」取用您的位置嗎？',
  'request-position-permission-content': '取得您所在的位置可以更快速找到您附近的超商門市喔！',
  'allow': '允許',
  'dont-allow': '不允許',
  'top-sales': '熱銷排行榜',
  'top-rated': '口碑好評推薦',
  'top-rated-og-desc': '在找高 CP 值的時尚單品嗎？眾多網友一致好評，穿上它準沒錯，VeryBuy 是你的最佳穿搭得力助手！',
  'top-rated-desc': '過去７天最受用戶推薦的商品',
  'sales-charts': '24H 熱銷排行榜',
  'sales-charts-desc': '時下最夯的大眾選擇，絕對不能錯過的時尚潮流，立即上 VeryBuy 掌握最新流行趨勢！',
  'watch-more': '看更多',
  'watch-more-discount': '看更多優惠＞',
  'new-arrivals': '每週新品',
  'new-arrivals-desc': '還在煩惱穿什麼衣服嗎？千款女裝、洋裝、上衣、下著等服飾，快來探索 VeryBuy 最新流行時尚新品，帶你走在時尚尖端！',
  'shopping-now': '立即逛逛',
  'shop-more': '逛更多',
  'browse-similar-items': '看賣家其他商品',
  'pull-to-anchor-more-product': '滑動查看更多商品',
  'release-to-anchor-more-product': '放開查看更多商品',
  'watch-more-product': '看其他商品',
  'image-detail': '圖文詳情',
  'theme-shopping-default-title': '你好，\n今天想怎麼穿？',
  'theme-detail-daily-news-title': '今日新品推薦',
  'theme-sale-list-title': '熱銷單品',
  'not-found-page': '噢！該頁面已不存在',
  'not-found-page-app': '噢！該頁面已不存在\n或將 APP 更新至新版後再試試',
  'go-back-to-prev-page': '返回上一頁',
  'go-back': '返回',
  'finish': '完成',
  'checkout-step-1': '確認訂單資訊(1/2)',
  'checkout-step-2': '選擇付款方式(2/2)',
  'no-more-personal-recommend': '到底了，還沒找到喜歡的嗎？',
  'no-more-theme-shopping-item': '到底了，還沒看夠嗎？',
  'shopping-other-categories': '逛逛其他分類',
  'found-new-version-title': '發現新版本啦',
  'found-new-version-content': '為了提供您更好的購物體驗，快與我們一起探索新的版本吧！',
  'update-now': '立即更新',
  'price-postfix': '起',
  'createCreditCard': '新增信用卡號',
  'get-coupon-dialog': {
    'title-for-app': '別讓抵用卷逾期失效',
    'content-for-app': '優惠已為您放進「我的抵用卷」中，是否要開啟抵用券快到期通知？',
    'positive-text-for-app': '開啟通知',
    'negative-text': '下次再說',
  },
  'request-noti-permission-alert': {
    'title': '尚未開啟通知',
    'content-for-transaction': '您沒有開啟通知權限，當訂單出貨或送達時將無法立即告知你。請點擊「設定」>「通知」> 打開「允許通知」',
    'content-for-coupon': '您沒有開啟通知權限，抵用券快到期時將無法立即告知你。請點擊「設定」>「通知」> 打開「允許通知」',
    'positive-text': '前往設定',
    'negative-text': '不用了，謝謝',
  },
  'go-to-see': '去看看',
  'get-coupon-toast': '領取成功\n已幫你放入「我的抵用券」',
  'download-app-button-content': '用 APP 查詢訂單更方便！',
  'period-task-success': '領取成功',
  'period-task-fail': {
    'email-verify': '請先完成手機與 Email 驗證',
    'unqualified': '請先下單並完成付款',
    'already-redeemed': '你已經領過囉！',
    'expired': '抱歉，活動已結束',
  },
  'last-login-method': {
    [LoginOrRegisterMethod.EMAIL]: '上次使用：E-mail 登入',
    [LoginOrRegisterMethod.APPLE]: '上次使用：Apple 登入',
    [LoginOrRegisterMethod.FACEBOOK]: '上次使用：Facebook 登入',
    [LoginOrRegisterMethod.LINE]: '上次使用：LINE 登入',
  },
  'onboarding-redeem-coupon-success': '抵用券領取成功',
  'redeem-coupon-fail': {
    'redeemed': '你已經領過囉！',
    'expired': '抱歉！抵用券已經發完囉',
    'is-over': '晚了一步！，優惠券剛被搶光了',
  },
  'transaction-promotions': {
    'title-for-web': '下載 VeryBuy',
    'title-for-app': '開啟小鈴鐺',
    'content-for-web': '噓！別說我沒告訴你... 使用 APP 追蹤訂單、查詢貨態更方便！',
    'content-for-app': '什麼時候才會收到貨？開啟推播通知，出貨就會立刻通知你！',
  },
  'mail-detail-transaction-title': {
    [SupportType.PaymentIssue]: '付款問題',
    [SupportType.DeliveryIssue]: '送貨問題',
    [SupportType.ReturnApplicationNotice]: '退貨申請通知',
    [SupportType.OrderIssue]: '訂單問題',
    [SupportType.DelayNotice]: '延遲通知',
    [SupportType.OutOfStockNotice]: '商品缺貨通知',
  },
  'mail-detail-page-title': {
    [SupportType.PaymentIssue]: '付款問題',
    [SupportType.DeliveryIssue]: '送貨問題',
    [SupportType.ReturnIssue]: '退貨問題',
    [SupportType.VCoin]: '勸敗點/抵用券',
    [SupportType.Other]: '其他',
    [SupportType.VEditorNotice]: 'V小編通知',
    [SupportType.CashRefundNotice]: '退現金通知',
    [SupportType.RefundNotice]: '退款通知',
    [SupportType.ReturnApplicationNotice]: '退貨通知',
    [SupportType.CreditCardCancelNotice]: '刷退通知',
    [SupportType.CreditCardIncompleteNotice]: '刷卡失敗通知',
    [SupportType.ElectronicInvoiceNotice]: '發票開立通知',
    [SupportType.ProductInformation]: '商品資訊',
    [SupportType.Activity]: '網站活動',
    [SupportType.OrderIssue]: '訂單問題',
    [SupportType.MemberIssue]: '會員問題',
    [SupportType.DelayNotice]: '延遲通知',
    [SupportType.OutOfStockNotice]: '缺貨通知',
  },
  'mailbox-list-title': {
    [MailType.Mail]: '個人訊息',
    [MailType.Notification]: '系統通知',
  },
  'mailbox-detail-quit-confirm': '現在離開將無法保留您輸入的內容\n確定離開放棄編輯嗎？',
  'transaction-list-title': {
    'all': '全部',
    [TransactionStatus.Unpaid]: '待付款',
    [TransactionStatus.Paid]: '已付款',
    [TransactionStatus.Stocking]: '備貨中',
    [TransactionStatus.Sent]: '已出貨',
    [TransactionStatus.Expired]: '已取消',
  },
  'transaction-no': '訂單編號：%{transactionNo}',
  'check-transaction': '查看訂單',
  'login-to-redeem': '登入領取',
  'credit-card-number': '信用卡號',
  'create-credit-card': '新增常用信用卡',
  'accepted-credit-card': '支持信用卡 / 簽帳卡卡號',
  'input-correct-card': '請輸入正確卡號',
  'input-correct-expiration-date': '請輸入正確到期日',
  'input-correct-cvc': '請輸入正確末三碼',
  'member-delete': '刪除會員帳號',
  'member-delete-info-html': '<p>1. 請確認帳戶內是否有尚未完成的<a href="%{orderHref}">訂單</a>，若刪除帳號後，將無法登入查詢配送狀況，並無法於線上提供售後服務，請務必留意！<br><br>2. 刪除帳號後，您將失去帳戶內所有未使用的<a href="%{vcoinHref}">勸敗點數</a>及<a href="%{couponHref}">抵用券</a>，無法復原。<br><br>3. 完成刪除帳戶後，非常勸敗將保留交易數據，僅使用於財務稽核目的。<br><br>4. 完成刪除帳戶後，您將無法登入並查看先前訂單歷史記錄。<br><br>5. 非常勸敗將保留未來拒絕用戶重覆建立新帳戶的申請要求。</p>',
  'member-delete-first-title': '重要提醒',
  'member-delete-first-subtitle': '刪除會員帳號前你需要先知道：',
  'member-delete-confirm-label': '我已確認並同意以上內容',
  'next-step': '下一步',
  'member-delete-second-title': '您的心聲是我們成長的養分',
  'member-delete-second-subtitle': '為了提供用戶更好的購物體驗，懇請您協助填寫問卷，讓我們知道在哪個面向能夠做得更好',
  'member-delete-selection-toast': '選擇至多三項哦！',
  'member-delete-q1-title': '請問您使用 VeryBuy 的過程中，最希望改善的功能或服務是什麼？',
  'member-delete-q1-subtitle': '必填，可複選（至多三項）',
  'member-delete-q2-title': '還有什麼建議想對我們說的嗎？',
  'member-delete-q2-subtitle': '非必填，文字上限 %{maxLength} 字',
  'member-delete-q2-placeholder': '請輸入文字',
  'member-delete-select': {
    '0': '優惠折扣',
    '1': '網頁 / APP 使用的流暢度',
    '2': '搜尋商品的精準度',
    '3': '網站上的商品資訊容易理解',
    '4': '商品品質',
    '5': '配送速度',
    '6': '售後服務不滿意（退換貨）',
    '7': '諮詢客服的協助',
  },
  'member-delete-third-title': '確認刪除您的帳號？',
  'member-delete-third-subtitle': '刪除後將無法再使用 VeryBuy 各項服務，且無法恢復您的帳號',
  'member-delete-button': '確認刪除',
  'member-deleted-message': {
    'title': '您的帳號已刪除',
    'content': '很遺憾見到您的離開 🥲\n希望未來有機會能再為您服務',
  },
  'line-login-duplicated-email-dialog': {
    'content': '我們為您找到相同驗證信箱的帳號，已為您綁定為同一個帳號，您可以使用 LINE 或 E-mail 更快速登入會員',
  },
  'save-credit-card': '儲存卡片訊息',
  'save-credit-card-create-error': '綁卡失敗，\n請再次確認卡片資料是否正確。',
  'credit-card-info': '新增信用卡時，VeryBuy 會進行一筆 1 元測試交易，此筆款項不會實際扣款，請放心依步驟進行認證。',
  'delete': '刪除',
  'confirm-delete-card': '確認刪除此卡號？',
  'create-credit-card-slogan': '新增信用卡，讓你結帳更快速唷',
  'create-credit-card-guide-title': '自主管理信用卡好放心',
  'create-credit-card-guide-content': '加密綁定常用信用卡讓您結帳時更便利，您也可以隨時點擊卡號旁的「垃圾桶」刪除卡片，請不用擔心！',
  'create-receipt-info': '新增發票資訊',
  'edit-receipt-info': '編輯發票資訊',
  'receipt-info': '發票資訊',
  'buyer-identifier': '統一編號',
  'buyer-name': '發票抬頭',
  'buyer-identifier-placeholder': '請輸入統一編號 8 碼',
  'buyer-name-placeholder': '請輸入公司或機關團體名稱（全名）',
  'invalid-buyer-identifier': '請輸入完整的統一編號（共八位數）',
  'invalid-buyer-name': '名稱格式錯誤，特殊字元僅部分支援： ,-_()/',
  'invalid-name-length': '名稱不能超過 255 個字',
  'zendesk-unread-msg': '%{count} 則新訊息',
  'recommand-item-title': '精選好物',
  'all-categories': '所有分類',
  'you-may-also-like': '你可能也喜歡',
  'service-policy': '服務政策',
  'terms-service': '服務條款',
  'anti-fraud': '貼心防詐騙',
  'about-verybuy': '關於 VeryBuy',
  'aboutus': '關於我們',
  'recruiting': '人才招募',
  'manufacturer-cooperation': '廠商合作申請',
  'latest': '最新',
  'weekly-hot': '每週熱門',
  'relevance': '綜合推薦',
  'vb-wholesale': 'VeryBuy 批發服務',
  'fast-delivery': '現貨出清',
  'fast-delivery-text': '快速到貨',
  'burger-item-categories': '全站分類',
  'sale': 'Sale',
  'categories-index': '全部分類',
  'check-origin-link': '查看原始物件',
  'checking-profile': '前往「我」的主頁',
  'customer-service': '客服',
  'cs-title': '聊聊',
  'my-customer-service': '我的客服小幫手',
  'tutorial-question-title': '最實用的操作技巧，你會幾個？',
  'tutorial-question-content': '不要說我沒告訴你，這麼好用的功能你怎麼能不知道？',
  'tutorial-question-button-cancel': '我都會了',
  'tutorial-question-button-tell-me': '快告訴我',
  'item-header-tutorial-title': '最常用的功能觸手可及',
  'item-header-tutorial-app-content': '分享、收藏、直達功能都在這，提供你更便捷的購物體驗。',
  'item-header-tutorial-content': '更多商品分類、分享、收藏、直達功能都在這，提供你更便捷的購物體驗。',
  'zoom-in-image-tutorial-title': '點擊滑動放大商品圖',
  'zoom-in-image-tutorial-content': '點擊一下商品圖片，滑動放大縮小看得更清楚唷！',
  'home-theme-shopping-tutorial': {
    'title': '以你喜歡的樣子，挑選穿衣風格',
    'content': '不用再煩惱要穿什麼了，快來看看這些場合可以怎麼搭吧！',
    'positive-btn-text': '開始逛逛',
  },
  'theme-shopping-detail-tutorial': {
    'title': '每日上新，別錯過你的穿搭靈感',
    'content': '追蹤你有興趣的主題，帶給你更多穿搭的可能性。',
    'positive-btn-text': '馬上探索',
  },
  'notification-dialog': {
    'transaction-title': '立即掌握訂單第一手通知！',
    'transaction-content': '想第一時間知道包裹出貨了嗎？立即開啟推播通知，訊息不漏接！',
    'negative-btn-text': '我再想想',
    'negative-btn-text2': '下次再說',
    'positive-btn-text': '開啟通知',
  },
  'cart-coupon-hint': '點選「結帳」後可使用抵用券、折扣碼',
  'pass': '略過',
  'next': '看下一個',
  'watch-now': '馬上探索',
  'welcome-announcement-title': 'VeryBuy 歡慶改版！',
  'welcome-announcement-content': '全新的 VeryBuy 操作介面，讓你逛起來更順暢！',
  'experience-now': '馬上體驗',
  'all': '全部',
  'member-edit': '編輯會員資料',
  'home-banner-title': '有什麼新鮮事',
  'home-category-title': '還想找什麼嗎？',
  'general-web-header-hint': {
    'title': '下方功能列搬到這囉！',
    'content': '查看購物車、查詢訂單、瀏覽全站分類，功能列讓你輕鬆搞定！',
  },
  'vcoin-to-cash': '結清購物金',
  'line-pay-payment-failure': 'LINE Pay 付款失敗，請確認已安裝 LINE 或使用其他付款方式',
  'apple-pay-payment-failure': 'Apple Pay 付款失敗，請確認 Apple Pay 設定是否完成或使用其他付款方式',
  'notification-tooltip': '您的訂單更新通知在這唷！',
  'filter-notification-tooltip': '好方便！現在可以更精準地找到你想要的衣服囉！',
  'customer-service-hint': '你好，有什麼需要為你服務的嗎？',
  'member-identity-verify': '會員身份驗證',
  'phone-verify-info': '為了避免您的交易身份被不肖人士竊取冒用，請於使用貨到付款前，綁定您帳戶的手機號碼。驗證成功後，代表您已同意 VeryBuy ',
  'get-verify-code': '獲取驗證碼',
  'your-phone-number': '您的手機號碼',
  'no-coupon-can-get': '目前沒有可領取的抵用券唷',
  'limited-get-coupon': '限時領券',
  'select-city-label': '請選擇縣市',
  'city': '城市',
  'city-and-district': '縣市、市區鄉鎮',
  'road': '街道',
  'store': '超商門市',
  'describe': '描述',
  'promotions': '優惠',
  'promotions-modal': '優惠活動',
  'category-tags-title': '主題找靈感',
  'show-more-tags': '顯示更多標籤',
  'show-fewer-tags': '顯示較少標籤',
  'paymentMethods': {
    'title': '付款方式',
    [PaymentMethods.CREDIT_CARD]: '信用卡付款',
    [PaymentMethods.COD]: '貨到付款 (試營運)',
    [PaymentMethods.CVS]: '超商代碼繳費 (先繳費不取貨)',
    [PaymentMethods.ATM]: 'ATM 轉帳',
    [PaymentMethods.LINE_PAY]: 'LINE Pay (可用 LINE Points 折抵)',
    [PaymentMethods.APPLE_PAY]: 'Apple Pay',
  },
  'deliveryMethods': {
    'title': '運送方式',
    [DeliveryMethods.HOME]: '宅配（提供貨到付款）',
    [`${DeliveryMethods.HOME}-for-gc`]: '宅配',
    [DeliveryMethods.CVS]: '7-11 取貨',
  },
  'delivery-modal-description-1': '※「國內運費」的實際金額將依結帳頁面所顯示為主，可能因選擇的配送地區及付款方式而產生額外費用。',
  'delivery-modal-description-2': '※ 國內免運優惠為「商品小計」扣除所有優惠折抵（包括抵用券、活動勸敗點等）後的金額，當該金額達到指定門檻時，即可享有免運優惠。',
  'support-transaction-id-picker-title': '選擇訂單編號',
  'support-faq-question-type': {
    'title': '請問我們該如何幫助您？',
    'select-default-text': '問題類型',
  },
  'support-faq-section-title': '也許你想問',
  'support-faq-page-contact-us': '有解決您的問題嗎？若還有其他需求',
  'support-loading-error-text': '系統忙線中，請稍後再試 ...',
  'support-submit-success': '訊息送出成功！\n客服專員將於 1-3 個工作天內回覆\n感謝您的耐心等候！',
  'support-submit-success-noti': '訊息送出成功！\n客服專員將於 1-3 個工作天內回覆，\n立即開啟通知，輕鬆獲取回覆提醒',
  'shipping-logistics-login-require': {
    'title': '為保護您的資料',
    'description': '查看「物流詳情」需登入',
  },
  'shipping-logistics-app-download': {
    'title': '開啟 VeryBuy Fashion',
    'description': '解鎖「物流追蹤」功能！',
    'unlock-now': '立即解鎖去',
    'modal-title': '物流追蹤功能示意',
    'modal-banner-description': '立即解鎖「物流追蹤」功能！',
    'modal-unlock-now': '解鎖去',
  },
  'recent-transaction-no-data-option': '目前尚未有購買資料',
  'add-mail-textarea-placeholder': '請提供您遇到的狀況說明...',
  'select-city-and-district': '選擇縣市、市區鄉鎮',
  'display-all': '展開全部',
  'select-road': '選擇街道',
  'select-store': '選擇超商門市',
  'member-settings': '設置',
  'notification': '優惠及貨態通知',
  'notification-enabled': '已開啟',
  'notification-disabled': '已關閉',
  'notification-remind': '建議開啟通知收取即時的優惠及貨態訊息唷！',
  'best-seller-share-msg': '這裡商品很不錯耶，分享給你快來一起逛逛吧！',
  'ratings': '分享 APP 好評',
  'language': '語言',
  'check-my-coupons': '先看看我的抵用券＞',
  'free-delivery-text': '再購買 <money>NT$%{money}</money> 即可<bold>享受免運服務</bold>！',
  'free-delivery-text-without-trans': '再購買 NT$%{money}\n即可享受免運服務！',
  'tw-free-delivery-text': '再購買 <money>NT$%{money}</money> 即可<bold>享受國內免運服務</bold>！',
  'tw-free-delivery-text-without-trans': '再購買 NT$%{money}\n即可享受國內免運服務！',
  'coupon-threshold-text': '再購買 <money>NT$%{couponDiff}</money> 即可使用 %{couponAmount} 元抵用券',
  'reach-free-delivery-threshold': '已達免運門檻',
  'reach-coupon-threshold': '已達抵用券門檻，可折抵 %{couponAmount} 元',
  'sales-promises': {
    'name': '鑑賞期保證',
    'cooling-off-period-title': '14 天鑑賞期',
    'cooling-off-period-desc': 'VeryBuy 提供安心購物 14 天鑑賞期服務！用戶收到貨品後 14 天內可申請退貨，不需支付額外產生之海外退貨費用，詳細請參考官網公告<a>退貨須知</a>',
    'late-delivery-title': '延遲發貨補償金',
    'late-delivery-desc': 'VeryBuy 承諾在商品頁面顯示之出貨時間內出貨（除疫情、天災等不可抗力因素除外）若超過時間將發予延遲補償金',
  },
  'daily-bonus': '每日簽到',
  'coupons-collection-area': '領券專區',
  'message-bar-placeholder': '發送訊息...',
  'cannot-reply-text': '還有什麼需要為您服務的嗎？',
  'service-link-text': '再次聯絡客服',
  'submit-message': '傳送',
  'message-reminder': '※ 本功能非即時通訊，客服回覆時效大約 1~3 個工作天，感謝您的耐心等候。',
  'add-mail-reminder': '※ 客服在線時間：週一至週五 10:00~19:00（國定假日除外）回覆時效大約 1~3 個工作天',
  'photos-number-limit': '圖片已達上限',
  'message-bad-network-connection': '糟糕！目前網路遇到亂流\n訊息未成功發送出去，請再試一次',
  'resend': '重新傳送',
  'no-current-mails': '你近三個月內沒有任何信件唷',
  'contact-us': '聯絡我們',
  'upload-image': '上傳圖片',
  'image-exceed-limit': '圖片超過 %{size}MB 上傳限制，請刪除後重新上傳',
  'event-page-download-modal-hint': 'APP 下單，滿 1500 元送 10%',
  'event-page-download-modal-button': '立即下載',
  'event-page-download-banner-button': '下載',
  'event-page-download-banner-title': 'VeryBuy Fashion',
  'event-page-download-banner-text': 'APP 下單，滿 1500 元送 10%',
  'color-amount-tag': '%{amount} 款可選',
  'single-color-amount-tag': '單一款式',
  'turn-on-notifications': '開啟通知',
  'webpush-subscribe-modal-positive': '開啟通知',
  'webpush-subscribe-modal-negtive': '下次再說',
  'webpush-subscribe-modal-title': '訂閱通知優惠折扣不漏接',
  'webpush-subscribe-modal-content': 'VeryBuy 流行時尚穿搭、話題熱銷企劃、限時免運折扣優惠，訂閱後第一時間通知您，再也不怕錯過好康消息。',
  'view-orders': '查看全部訂單',
  'select-all': '全選',
  'checkout': '結帳',
  'go-checkout': '去結帳',
  'delivery-name': '名字',
  'country-code': '國碼',
  'area-phone': '手機號碼',
  'delivery-country': '國家',
  'delivery-country-region': '國家／區域',
  'delivery-address': '街道地址',
  'delivery-address-for-gc': '地址',
  'delivery-address-placeholder': 'e.g. 街道名稱、巷弄、門牌號',
  'delivery-city-placeholder': '請輸入城市',
  'delivery-zip-code-placeholder': '請輸入郵遞區號',
  'zip-code': '郵遞區號',
  'district-picker-title': '選擇縣市、市區鄉鎮',
  'third-party-change-password-ignore-text': '您的帳號為使用 <method>%{loginMethod}</method> 登入，無法變更密碼',
  'different-currency-hint': {
    'transaction-result': '實際金額將依匯率收取台幣',
    'transaction-detail': '實際金額將依匯率收取台幣',
    'default': '結帳畫面將依匯率實際收取台幣',
  },
  'bill-detail-TWD-price': '（ 約 %{symbol} %{price} ）',
  'patch-transaction-error-text': {
    [CommonErrors.ERR_INVALID_ARGUMENT]: '抱歉！您的訂單送出失敗 請再嘗試一次',
    [TransactionErrors.ERR_TRANSACTION_CHANGE_FAILED_ALREADY_PAID]: '已付款完成',
  },
  'patch-transaction-default-error': '抱歉！發生錯誤，請再試一次',
  'vendor-introduction': '品牌介紹',
  'limit-coupon-content': '*限時限量 <limit>%{limit}</limit> 張',
  'multi-piece-discount-hint': {
    title: '多件優惠：',
    text: '本商品購買第二件起，每件減價 %{discount} 元',
  },
  'check-multi-piece-discount': {
    titleForConform: '已符合',
    titleForNotConform: '不符合',
    text: '本商品購買第二件起，每件減價 %{discount} 元',
  },
  'vendor-page-notification-dialog': {
    title: '訂閱「賣家通知」訊息不漏接',
    text1: '👍 賣家上新品通知',
    text2: '👍 商品降價通知',
    text3: '👍 賣家專屬活動通知',
    'negative-btn-text': '下次再說',
  },
  'vendor-page-notification-bar': {
    context1: '喜歡這個賣家的商品嗎 ？',
    context2: '立即收藏，新品上架不錯過 ！',
    'button-text': '收藏賣家',
  },
  'share-link-success': '複製連結成功',
  'share-link-now': '立即分享邀請連結',
  'share-link-mobile-message': '邀請好友賺獎金\n賺取 50 點勸敗點',
  'invite-friend-description': {
    'first-description': '邀請好友加入 VeryBuy Fashion，享勸敗點獎勵，購物立即折抵！',
    'second-description-register': '朋友註冊會員並完成第一筆訂單',
    'second-description-vcoin': '+50 勸敗點',
    'third-description': '邀請好友成為 VeryBuy 會員，且完成第一筆訂單，即可獲得 50 點勸敗點，可於購物結帳時折抵。邀請人數沒有上限，邀請越多朋友領越多！',
  },
  'invite-friend-step': {
    'header-1': '第１步：分享連結',
    'header-2': '第２步：好友註冊會員',
    'header-3': '第３步：好友完成訂單',
    'header-4': '第４步：收到獎勵',
    'description-1': '<text>按下<mark>「複製」</mark>後，再由你將專屬連結分享給朋友。或按下<mark>「立即分享邀請連結」</mark>輕鬆分享連結！</text>',
    'description-2': '<text>朋友按下你的<mark>「專屬邀請連結」</mark>，成功註冊為 VeryBuy Fashion 會員！</text>',
    'description-3': '<text>朋友完成第一筆訂單並付款成功！</text>',
    'description-4': '<text>完成上述步驟後，系統將會發送獎勵至帳戶的<mark>「我的勸敗點」</mark>！</text>',
  },
  'suggest-vendor-title': '您可能想找的品牌',
  'fuzzy-search-button-text': '看更多相關產品',
  'company-information': '非常網路科技股份有限公司 ｜ 統編：53538135',
  'atm-maintaining-pay-by-other': 'ATM 付款維護中，請使用其他付款方式',
  'fuzzy-search-discovery': '你可能想找',
  'try-to-use-other-condition': '換個篩選條件試試！',
  'sales-amount': '總銷量 > %{amount}',
  'expand-details': '展開明細',
  'collapse-details': '收合明細',
  'cart-shipping-hint': '實際運費以結帳頁面為準',
  'bill-detail-shipping-hint-title': '運費計算說明',
  'bill-detail-shipping-hint-context': '國內免運優惠為「商品小計」扣除所有優惠折抵（包括抵用券、活動勸敗點等）後的金額，當該金額達到指定門檻時，即可享有免運優惠',
  'bill-detail-cross-border-shipping-hint-title': '運費計算說明',
  'bill-detail-cross-border-shipping-hint-context': '跨境物流費包括海外當地的運輸費用、海（空）運費、超材費用，及部分關稅等項目。實際收費將根據訂單的最終結算明細為準，且此費用將不適用於任何促銷優惠或折扣',
  'bill-detail-event-vcoin-hint-title': '活動勸敗點折抵說明',
  'bill-detail-event-vcoin-hint-context': '最高可折抵商品小計 15%',
  'reselect-coupon-alert': '請重新選擇優惠方案',
  'reselect-delivery-mode-alert': '請重新選擇配送方式',
  'try-this-combo': '試試這樣搭配',
  'change-batch': '換一批',
  'watch-item': '看商品介紹',
  'select-shipping-method-alert': '請先選擇運送方式',
  'alert': '注意',
  'r18-modal': {
    'title': '限制級商品警語',
    'hint-text': '本類為限制級商品',
    'trans-text': '限 <highLight>18 歲</highLight> 以上會員才可瀏覽及購買',
    'hint-sub-text': 'VeryBuy Fashion 內容依據台灣網路內容分級辦法處理，年滿 18 歲以上或達當地國家法定年齡人士；且願接受本站內容及各項條款方可進入',
    'negative-title': '我未滿 18 歲',
    'negative-text': '離開',
    'positive-title': '我已滿 18 歲',
    'positive-text': '進入',
  },
};
