import { Dispatch } from 'react';
import { createAsyncAction } from 'typesafe-actions';

import {
  DataFromApi,
  FilterConfigPage,
} from '../../types/FilterConfigResponse';

export type CategoryPayload = {
  page: FilterConfigPage.Category;
  catId: number;
};

export type SearchPayload = {
  page: FilterConfigPage.Search;
  queryText: string;
};

export type PayloadWithId = {
  page: FilterConfigPage.BestSellers;
  id: string;
};

export interface DefaultPayload {
  page:
    | FilterConfigPage.OnSale
    | FilterConfigPage.NewArrivals
    | FilterConfigPage.Default;
}

export type FilterConfigPayloadWithoutDispatch =
  | CategoryPayload
  | SearchPayload
  | PayloadWithId
  | DefaultPayload;

export type FilterConfigPayload = FilterConfigPayloadWithoutDispatch & {
  dispatch: Dispatch<any>;
};

export interface FilterConfigSuccessPayload {
  page: FilterConfigPage;
  data: DataFromApi;
}

export interface FilterConfigFailPayload {
  page: FilterConfigPage;
  error: Error;
}

export const getFilterConfigActions = createAsyncAction(
  'verybuy/GET_FILTER_CONFIG',
  'verybuy/GET_FILTER_CONFIG_SUCCESS',
  'verybuy/GET_FILTER_CONFIG_FAIL'
)<FilterConfigPayload, FilterConfigSuccessPayload, FilterConfigFailPayload>();
