import { PureComponent } from 'react';
import { Circle, G, Path, Svg } from 'react-native-svg';

import { white } from '../../../constants/color';

const DEFAULT_SVG_SIZE = 24;

interface Props {
  size?: number;
  fillColor?: string;
}

interface DefaultProps {
  size: number;
  fillColor: string;
}

type PropsWithDefaults = Props & DefaultProps;

export default class IconNavLeft extends PureComponent<Props> {
  static defaultProps: DefaultProps = {
    size: DEFAULT_SVG_SIZE,
    fillColor: white,
  };

  render() {
    const { size, fillColor } = this.props as PropsWithDefaults;
    const sizeString = size.toString();
    const scale = size / DEFAULT_SVG_SIZE;

    return (
      <Svg
        width={size}
        height={size}
        viewBox={`0 0 ${sizeString} ${sizeString}`}
      >
        <G fill={fillColor} scale={scale}>
          {/* eslint-disable-next-line max-len */}
          <Circle cx={12} cy={12} r={12} fill="none" />
          <Path
            d="M16.5 21.61a.6.6 0 01-.43-.18l-9-9a.6.6 0 010-.86l9-9a.61.61 0 01.86.86L8.37 12l8.56 8.57a.61.61 0 01-.43 1z"
            fill={fillColor}
          />
        </G>
      </Svg>
    );
  }
}
