import { useCallback, useEffect, useRef } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { skip } from 'rxjs/operators';

import IconCancel from '../../assets/svg/IconCancel';
import { isWebPlatform } from '../../boot/utils';
import { white } from '../../constants/color';
import useActions from '../../hooks/useActions';
import { actionsList } from '../../redux/rootAction';
import { makeHasReadGuideByKey } from '../../redux/selectors/tutorialGuideSelector';
import { useSelector } from '../../redux/utils';
import * as navigationService from '../../utils/navigationService';
import { S } from './styles';

const animation = {
  from: {
    transform: [{ translateY: -3 }],
  },
  to: {
    transform: [{ translateY: 3 }],
  },
};

export interface Props {
  guideKey: string;
  routeName: string;
  hintDirection: 'top' | 'bottom' | 'left' | 'right' | 'none';
  text: string;
  subText?: string;
  style?: StyleProp<ViewStyle>;
  boxStyle?: StyleProp<ViewStyle>;
  maxTextWidth?: number;
  triangleOffset?: number;
  triangleAlignment?: 'flex-start' | 'center' | 'flex-end';
  shouldCloseAfterScreenDiff?: boolean;
}

const Hint = (props: Props) => {
  const {
    guideKey,
    routeName,
    style,
    boxStyle,
    hintDirection,
    text,
    subText,
    triangleOffset = 0,
    triangleAlignment = 'center',
    maxTextWidth,
    shouldCloseAfterScreenDiff = true,
  } = props;
  const setGuideStatus = useActions(actionsList.setGuideStatus);
  const setHasRead = useCallback(() => {
    setGuideStatus({ guideKey });
  }, [setGuideStatus, guideKey]);
  const onTransitionEnd = useCallback(
    (property: string) => {
      if (property === 'opacity') {
        setHasRead();
      }
    },
    [setHasRead]
  );

  useEffect(() => {
    if (!shouldCloseAfterScreenDiff) return;
    const sub = navigationService.screenSubject$
      .pipe(skip(1))
      .subscribe(({ currentScreen, prevScreen }) => {
        if (currentScreen?.key !== prevScreen?.key) {
          setHasRead();
        }
      });

    return () => {
      sub.unsubscribe();
    };
  }, [setHasRead, routeName, shouldCloseAfterScreenDiff]);

  const tooltipRef = useRef<any>(null);
  const handleClose = useCallback(() => {
    tooltipRef.current?.transitionTo({ opacity: 0 }, 400);
  }, []);

  return (
    <S.TooltipWrapper
      style={style}
      animation={animation}
      iterationCount="infinite"
      direction="alternate"
      duration={800}
      ref={tooltipRef}
      onTransitionEnd={onTransitionEnd}
      useNativeDriver={!isWebPlatform}
    >
      <S.Content
        hintDirection={hintDirection}
        triangleAlignment={triangleAlignment}
      >
        <S.Triangle
          hintDirection={hintDirection}
          triangleOffset={triangleOffset}
        />
        <S.Tooltip style={boxStyle}>
          <S.TextWrapper maxWidth={maxTextWidth}>
            <S.Text isBold={!!subText}>{text}</S.Text>
            <S.SubText>{subText}</S.SubText>
          </S.TextWrapper>
          <S.IconWrapper accessibilityRole="button" onPress={handleClose}>
            <IconCancel size={16} fillColor={white} />
          </S.IconWrapper>
        </S.Tooltip>
      </S.Content>
    </S.TooltipWrapper>
  );
};

interface ContainerProps extends Props {
  enabled?: boolean;
  onVisibleChanged?: (isVisible: boolean) => void;
}

export default (props: ContainerProps) => {
  const { enabled = true, guideKey, onVisibleChanged, ...rest } = props;
  const hasRead = useSelector(makeHasReadGuideByKey(guideKey));
  const isVisible = enabled && !hasRead;

  useEffect(() => {
    onVisibleChanged?.(isVisible);
  }, [isVisible, onVisibleChanged]);

  if (!isVisible) {
    return null;
  }

  return <Hint guideKey={guideKey} {...rest} />;
};
